import React, { FC } from "react"
import { graphql, PageProps } from "gatsby";
import { BaseLayout } from "@layouts";
import { useForm } from "react-hook-form";
import { Button, Input, TextArea } from "@components";
import { sendMail } from "@services";
import { TContactDetails } from "../types/contact";
import { TypeImage } from "@types";
import { GatsbyImage } from "gatsby-plugin-image";

type ContactData = {
    introBg: TypeImage
}
type ContactContext = {
    locale: string
}


const defaultValues = {
    firstName         : "",
    lastName          : "",
    email             : "",
    message           : "",
    recaptcha_response: ""
}

const Contact: FC<PageProps<ContactData, ContactContext>> = ( { data } ) => {
    const { handleSubmit, register, setError, clearErrors, reset, formState: { errors, isSubmitting, isSubmitSuccessful } } = useForm<TContactDetails>()

    const onSubmit = handleSubmit( async ( data ) => {
        await sendMail<TContactDetails>( {
            url                   : "https://whoisniko.com/contact/sendmail.php",
            data,
            onSuccess             : () => {
                reset( defaultValues )
            },
            recaptchaErrorCallback: () => {
                setError( "general", {
                    type   : "manual",
                    message: "Captcha is missing",
                } );
            },
            fetchErrorCallback    : ( e ) => {
                setError( "general", {
                    type   : "manual",
                    message: e.message,
                } );
            }
        } )
    } )

    return (
        <BaseLayout title={ "Contact" } className={ "contact container" }>
            <GatsbyImage className={ "bg-image__wrapper" } imgClassName={ "bg-image__img" } image={ data.introBg.childImageSharp.gatsbyImageData } alt={ "" }/>
            <h1>Drop me a line</h1>
            <form className={ "form" } onSubmit={ onSubmit }>
                { isSubmitSuccessful && <p className={ "contact__success-message" }>Thank you for your message! I will get back to you as soon as possible.</p> }
                <Input label={ "First Name" } errors={ errors } { ...register( "firstName", { required: "Required" } ) } />
                <Input label={ "Last Name" } errors={ errors } { ...register( "lastName", { required: "Required" } ) }/>
                <Input label={ "Email" } errors={ errors } { ...register( "email", { required: "Required" } ) }
                       type={ "email" }/>{/*<PhoneInputWrapper name={ "phone" } label={ "Phone" } errors={ errors } control={ control }/>*/ }
                <TextArea label={ "Message" } errors={ errors } { ...register( "message" ) } rows={ 6 }/>
                { errors.general && <p className={ "input__error" }>{ errors.general.message }</p> }
                <p className={"recaptcha-message"}>
                    This site is protected by reCAPTCHA and the Google <a target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/privacy">Privacy Policy</a> and <a
                    target={ "_blank" } rel={ "noopener noreferrer" } href="https://policies.google.com/terms">Terms of Service</a> apply.
                </p>
                <Button disabled={ isSubmitting } look={ "primary" } type={ "submit" } onClick={ () => clearErrors() }>Submit</Button>
            </form>

        </BaseLayout>
    )
}

export default Contact

export const query = graphql`    {
    introBg: file(relativePath: {eq: "niko.jpg"}) {
        relativePath
        childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 80)
        }
    }
}`
